<template>

  <div>

    <b-row>
      <b-col md="4">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Seçilen Menü')"
                label-for="brand"
              >

                <b-form-select
                  v-model="selectedMenuType"
                  :options="menuTypes"
                />

              </b-form-group>
              <app-collapse>

                <app-collapse-item title="Ürün Kategorisi">
                  <b-form-group
                    :label="$t('Menü Başlığı')"
                    label-for="menu-title"
                  >

                    <b-form-input
                      id="menu-title"
                      v-model="menuTitle"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <b-form-group
                    :label="$t('Eklenecek Kategori')"
                    label-for="other_categories"
                  >

                    <v-select
                      id="other_categories"
                      v-model="selectedCategories"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      :options="categoriesData"
                      :reduce="val => val.value"
                      label="text"
                      :clearable="false"
                      input-id="other_categories"
                    />

                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
                    <b-button

                      id="import-modal-toggle-btn"
                      variant="primary"
                      size="sm"
                      @click="saveData('category')"
                    >

                      <span class="text-nowrap">{{ $t("Menüye Ekle") }}</span>
                    </b-button>
                  </div>
                </app-collapse-item>

                <app-collapse-item title="Ürün Markası">
                  <b-form-group
                    :label="$t('Menü Başlığı')"
                    label-for="menu-title"
                  >

                    <b-form-input
                      id="menu-title"
                      v-model="menuTitle"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <b-form-group
                    :label="$t('Marka')"
                    label-for="brand"
                  >

                    <b-form-select
                      v-model="selectedBrands"
                      :options="brandsData"
                    />

                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
                    <b-button

                      id="import-modal-toggle-btn"
                      variant="primary"
                      size="sm"
                      @click="saveData('brand')"
                    >

                      <span class="text-nowrap">{{ $t("Menüye Ekle") }}</span>
                    </b-button>
                  </div>
                </app-collapse-item>

                <app-collapse-item title="Özel Url">
                  <b-form-group
                    :label="$t('Menü Başlığı')"
                    label-for="menu-title"
                  >

                    <b-form-input
                      id="menu-title"
                      v-model="menuTitle"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <b-form-group
                    :label="$t('Menü Url')"
                    label-for="menu-url"
                  >

                    <b-form-input
                      id="menu-url"
                      v-model="menuUrl"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
                    <b-button

                      id="import-modal-toggle-btn"
                      variant="primary"
                      size="sm"
                      @click="saveData('url')"
                    >

                      <span class="text-nowrap">{{ $t("Menüye Ekle") }}</span>
                    </b-button>
                  </div>
                </app-collapse-item>
                <app-collapse-item title="Grup Başlığı">
                  <b-form-group
                    :label="$t('Grup Başlığı')"
                    label-for="menu-title"
                  >

                    <b-form-input
                      id="menu-title"
                      v-model="menuTitle"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
                    <b-button

                      id="import-modal-toggle-btn"
                      variant="primary"
                      size="sm"
                      @click="saveData('group_title')"
                    >

                      <span class="text-nowrap">{{ $t("Menüye Ekle") }}</span>
                    </b-button>
                  </div>
                </app-collapse-item>
                <app-collapse-item title="Basit İçerik">
                  <b-form-group
                    :label="$t('İçerik Başlığı')"
                    label-for="menu-title"
                  >

                    <b-form-input
                      id="menu-title"
                      v-model="menuTitle"
                      autofocus
                      autocomplete="off"
                      trim
                    />

                  </b-form-group>
                  <b-form-group
                    :label="$t('Basit İçerik')"
                    label-for="simple-content"
                  >

                    <quill-editor
                      v-model="simpleContent"
                      :options="snowOption"
                    />

                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
                    <b-button

                      id="import-modal-toggle-btn"
                      variant="primary"
                      size="sm"
                      @click="saveData('simple_content')"
                    >

                      <span class="text-nowrap">{{ $t("Menüye Ekle") }}</span>
                    </b-button>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="8">
        <b-card

          class="mb-0"
        >
          <b-card-title>
            Menüyü Düzenle
          </b-card-title>
          <b-row>
            <b-col md="12">

              <hr>
              <menu-draggable-component
                :tasks="menuData"
                @delete="deleteData"
                @moveElement="moveElement"
              />
            </b-col>
          </b-row>
          <hr>
          <div>
            <b-button
              variant="primary"
            >
              Kaydet
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCard, BRow, BCol, VBModal, BFormGroup, BCardTitle, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import menuStoreModule from './menuStoreModule'
import MenuDraggableComponent from './MenuDraggableComponent.vue'

export default {
  components: {
    BCard,
    MenuDraggableComponent,
    BRow,
    BButton,
    BFormInput,
    BCol,
    vSelect,
    BFormSelect,
    quillEditor,
    // Form Validation
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    BCardTitle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
      menuTitle: '',
      menuGroupTitle: '',
      menuUrl: '',
      simpleContent: '',
      menuData: [
      ],
    }
  },
  watch: {
    selectedMenuType(val) {
      this.fetchMenuData(val)
    },
  },
  created() {
    this.fetchMenuData(1)
  },
  methods: {
    moveElement() {
      store.dispatch('app-menu/changeMenuOrdering', { ordering_data: this.menuData })
        .then(() => {

        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    fetchMenuData(menuTypeId) {
      store.dispatch('app-menu/fetchMenus', { menu_type: menuTypeId })
        .then(response => {
          this.menuData = response.data.data
          // console.log(this.menuData)
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    saveData(type) {
      let param = ''
      let typeId = 1
      if (type === 'group_title') {
        typeId = 0
        param = this.menuTitle
      }
      if (type === 'category') {
        typeId = 1
        param = this.selectedCategories.toString()
      } else if (type === 'brand') {
        typeId = 2
        param = this.selectedBrands.toString()
      } else if (type === 'url') {
        typeId = 3
        param = this.menuUrl
      } else if (type === 'simple_content') {
        typeId = 4
        param = this.simpleContent
      }

      const dataobj = {
        param,
        menu_type: this.selectedMenuType,
        item_type: typeId,
        name: this.menuTitle,
        ordering: 0,
        parent_id: 0,
      }
      store.dispatch('app-menu/addMenu', dataobj)
        .then(() => {
          this.fetchMenuData(this.selectedMenuType)

          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('BuRolü Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-menu/deleteMenu', { id })
            .then(() => {
              this.fetchMenuData(this.selectedMenuType)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-menu'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, menuStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const categoriesData = ref([])
    const selectedCategories = ref(null)
    const selectedBrands = ref(null)
    const brandsData = ref([])
    const selectedMenuType = ref(1)
    const menuTypes = [
      { text: 'Ana Menü', value: 1 },
      { text: 'Alt Menü', value: 2 },

    ]
    const fetchCategories = () => {
      store.dispatch('app-menu/fetchCategories', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name_with_parents, value: val.id }))
          categoriesData.value = arr
        })
    }
    fetchCategories()
    const fetchBrands = () => {
      store.dispatch('app-menu/fetchBrands', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
          brandsData.value = arr
        })
    }
    fetchBrands()
    const resetRoleData = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRoleData)
    return {
      categoriesData,
      brandsData,
      selectedMenuType,
      menuTypes,
      refFormObserver,
      getValidationState,
      selectedCategories,
      selectedBrands,
      resetRoleData,
      resetForm,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
