<template>
  <draggable
    tag="div"
    class="item-container dragArea"
    :list="tasks"
    :group="{ name: 'g1' }"

    @end="moveElement"
  >
    <div
      v-for="el in tasks"
      :key="el.id"
      class="item-group"
      tag="li"
    >
      <div class="item">
        <app-collapse>

          <app-collapse-item
            :titletype="el.item_type==1?'Kategori':el.item_type==2?'Marka':el.item_type==0?'Grup':el.item_type==3?'Url':el.item_type==4?'Basit İçerik':''"
            :title="el.name"
          >
            <b-row v-if="el.item_type==1">
              <b-col
                v-for="(item,index) in el.param"
                :key="index"

                md="12"
              >
                <b-badge

                  variant="light-primary"
                >
                  {{ item.name }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row v-if="el.item_type==2">
              <b-col
                v-for="item in el.param"
                :key="item.id"

                md="12"
              >
                <b-badge
                  v-for="item in el.param"

                  :key="item.id"
                  variant="light-warning"
                >
                  {{ item.name }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row v-if="el.item_type==3">
              <b-col
                v-for="item in el.param"
                :key="item.id"

                md="12"
              >
                <b-badge

                  variant="light-warning"
                >
                  {{ item }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row v-if="el.item_type==4">
              <b-col
                v-for="item in el.param"
                :key="item.id"

                md="12"
              >
                <b-badge
                  variant="light-secondary"
                >
                  {{ item }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row v-if="el.item_type==0">
              <b-col
                v-for="item in el.param"
                :key="item.id"

                md="12"
              >
                <b-badge
                  variant="light-secondary"
                >
                  {{ item }}
                </b-badge>
              </b-col>
            </b-row>
            <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 ">
              <b-button
                variant="flat-danger"
                size="sm"
                @click="deleteData(el.id)"
              >
                Sil
              </b-button>
            </div>
          </app-collapse-item>
        </app-collapse>
      </div>
      <menu-draggable-component

        class="item-sub"
        :tasks="el.sub"
        @delete="deleteData"
        @moveElement="moveElement"
      />
    </div>

  </draggable>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import {
  BButton, BBadge, BRow, BCol,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-self-import

export default {
  name: 'NestedDraggable',
  components: {
    BButton,
    BBadge,
    BRow,
    BCol,
    draggable,
    // eslint-disable-next-line vue/no-unused-components
    AppCollapse,
    // eslint-disable-next-line vue/no-unused-components
    AppCollapseItem,
    // eslint-disable-next-line vue/no-unused-components
    // eslint-disable-next-line import/no-self-import
    MenuDraggableComponent: () => import('./MenuDraggableComponent.vue'),
  },
  props: {
    tasks: {
      required: true,
      type: Array,
    },
  },
  methods: {

    moveElement(e) {
      this.$emit('moveElement', e)
    },
    deleteData(id) {
      this.$emit('delete', id)
    },
  },
}

</script>

<style scoped>

.item-container {
  max-width: 50rem;
  margin: 0;
}
.item {

  border: 1px solid #ebe9f1;
  background-color: #fefefe;
}
.dragArea {
  min-height: 25px;
 background: rgb(random(255), random(255), random(255))
}
.item-sub {
  margin: 0 0 0 1rem;
}
</style>
